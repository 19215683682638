import { TrMetricsService } from "services/TrMetricsService";
import { onDocumentReady } from "./utils/onDocumentReady";

const getBrowserLocales = () => {
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages[0];

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales;
};

const currentLocale = getBrowserLocales();
if (currentLocale) {
  window.zESettings = {
    webWidget: {
      chat: {
        connectOnPageLoad: false,
      },
      locale: currentLocale
    }
  };
}

function addZeSnippet() {
  const script = document.createElement("script");
  script.id = "ze-snippet";
  script.src = "https://static.zdassets.com/ekr/snippet.js?key=5d5af502-eb20-48d4-96fc-1e28ad140cde";
  document.body.appendChild(script);

  TrMetricsService.fire("zendesk_help_button_clicked");

  this.classList.add("is-active");

  document.querySelector("#ze-snippet").addEventListener("load", () => {
    setTimeout(() => {
      window.zE(() => window.zE('messenger', 'open'));
      const tagsToApply = ['script-successful'];

      if (currentLocale) {
        tagsToApply.push(currentLocale);
        window.zE('messenger:set', 'locale', currentLocale);  // Set the locale using the new method
      }

      window.zE('messenger:set', 'conversationTags', tagsToApply);

      window.zE("messenger", "loginUser", function (callback) {
        fetch('/api/v3/zendesk_messaging_token').then(
          (response) => response.json().then(data => ({
            data: data,
          })).then(res => {
            callback(res.data.token)
            }).catch((error) => {
              console.log(error);
          })
        )
      });
    }, 1000);
  });
}

onDocumentReady(() => {
  document.querySelector(".zendesk-help-button").addEventListener("click", addZeSnippet)
})
